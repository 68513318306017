/* * {
  --white: #f4f4f2;
  --dark1: #e8e8e8;
  --dark2: #bbbfca;
  --dark3: #495464;
  --black: #000000;
} */

.Card__Main {
  min-width: 250px;
  height: 130px;
  padding: 0.75rem;
  /* margin-top: 2rem; */
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  background-color: var(--white);

  border-radius: 8px;
  border: solid var(--dark2) 1px;
  box-shadow: 0px 2px 4px color-mix(in oklch, var(--dark2), transparent 50%);
}

/* ####################  CARD HEADING  #################### */

.Card__Heading {
  width: 100%;
  /* height: fit-content; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: #fff; */
  /* border: solid #ddd 2px; */
  /* border-radius: 8px; */
}
.Card__Heading > .Profile__Name {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  color: gray;
}
.Profile__Photo {
  width: fit-content;
  height: 26px;
  display: flex;
  overflow: hidden;
  position: relative;
}
.Profile__Photo > img {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  object-fit: cover;
}
.Profile__Photo > .Profile__Available {
  width: 12px;
  height: 12px;
  background: rgb(69, 190, 53);
  border: solid var(--white) 2px;
  position: absolute;
  transform: translate(15px, 15px);
  border-radius: 100%;
}

.Profile__Photo > .Is_Available__false {
  background-color: #bbb;
}

/* ####################  CARD CONTENT  #################### */

.Card__Content {
  display: flex;
  padding: 0px 1px;
  padding-bottom: 2px;
  gap: 6px;
  /* align-items: flex-start; */
}
h1 {
  margin: 0;
}
.Card__Content > .Card__Status__Icon {
  padding-top: 1px;
}
.Card__Content > .Ticket__Title {
  font-size: 14px;
  font-weight: 500;
  text-align: left;

  --max-lines: 2;

  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

/* ####################  CARD FOOTER  #################### */

.Card__Footer {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 0.5rem;
}
.Card__Footer > .Priority__Icon {
  /* padding: 0 8px; */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border: solid var(--dark2) 1px;
  margin: 0;
  border-radius: 6px;
}

.Card__Footer > .Message__Tag {
  height: 30px;
  padding: 0 9px;
  display: flex;
  color: gray;
  font-weight: 400;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border: solid var(--dark2) 1px;
  margin: 0;
  border-radius: 6px;
}
